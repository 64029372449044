export default defineComponent({
  props: {
    direction: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  setup: function setup() {},
  methods: {
    geticonbydirection: function geticonbydirection(direction) {
      if (direction == 'bottom-left') {
        return 'mdi-arrow-bottom-left';
      } else if (direction == 'bottom-right') {
        return 'mdi-arrow-bottom-right';
      } else if (direction == 'bottom') {
        return 'mdi-arrow-down';
      } else if (direction == 'top-left') {
        return 'mdi-arrow-top-left';
      } else if (direction == 'top-right') {
        return 'mdi-arrow-top-right';
      } else if (direction == 'top') {
        return 'mdi-arrow-top';
      } else if (direction == 'left') {
        return 'mdi-arrow-left';
      } else if (direction == 'right') {
        return 'mdi-arrow-right';
      } else {
        return '';
      }
    }
  }
});